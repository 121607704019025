import React from 'react';
import SubmitButton from './button-types/submit-form';
import InternalButtonLink from './button-types/internal-link';
import ExternalLinkButton from './button-types/external-link';
import LoadItemsButton from './button-types/load-items';
import ApplyButton from './button-types/apply-button';
import VideoModal from '../videoModal';
import { ButtonProps } from './buttonProps';

const ButtonControl: React.FC<ButtonProps> = (props) => {
  const { contentType } = props;
  switch (contentType) {
    case 'internalLink':
      return <InternalButtonLink {...props} />;
    case 'externalLink':
      return <ExternalLinkButton {...props} />;
    case 'careers':
      return <ApplyButton {...props} />;
    case 'submit':
      return <SubmitButton {...props} />;
    case 'loadItems':
      return <LoadItemsButton {...props} />;
    default:
      return (
        <VideoModal
          className={props.className}
          styles={props.styles}
          buttonTitle={props.buttonTitle}
          buttonType={props.buttonType}
          videoId={props.videoId}
          icon={props.icon}
          iconAlt={props.iconAlt}
          linkTitle={props.linkTitle}
          section={props.section}
          hideLink={props.hideLink}
          textStyle={props.textStyle}
        />
      );
  }
};

export default ButtonControl;
