import React from 'react';

interface Props {
  title: string;
  iconSm: any;
  iconMd: any;
  iconLg: any;
  iconAlt: string;
}

const MasterButton: React.FC<Props> = (props) => {
  const { iconLg: IconLg, iconMd: IconMd, iconSm: IconSm } = props;
  return (
    <div className="flex items-center">
      <span className="pr-4">
        <IconLg className="hidden laptop-large:block" />
        <IconMd className="hidden laptop-standard:block laptop-large:hidden" />
        <IconSm className="laptop-standard:hidden" />
      </span>
      <p className="mobile:iconSm laptop-standard:iconLg laptop-standard:font-normal laptop-large:font-bold laptop-large:uppercase">
        {props.title}
      </p>
    </div>
  );
};

export default MasterButton;
