import React from 'react';
import { ButtonProps } from '../buttonProps';

type LoadItemsButtonType = Pick<
  ButtonProps,
  'linkTitle' | 'styles' | 'handleClick' | 'displayButton' | 'icon'
>;

const LoadItemsButton: React.FC<LoadItemsButtonType> = (props) => {
  return (
    <div className={`${props.styles ?? ''} ${props.displayButton ?? ''}`}>
      <button className="iconLg loadButton px-4 h-13 flex items-center" onClick={props.handleClick}>
        <span className="w-max">{props.linkTitle}</span>
        {props.icon && <props.icon className="ml-5 color-black" />}
      </button>
    </div>
  );
};

export default LoadItemsButton;
