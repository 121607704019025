const ctaStyleHandler = (sectionName?: string): string => {
  switch (sectionName) {
    case 'news':
      return 'cta-link-container';
    case 'carousel':
      return '';
    case '2up-single':
      return 'cta-link-container';
    default:
      return 'cta-link-container';
  }
};

export default ctaStyleHandler;
