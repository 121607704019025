import React from 'react';

interface Props {
  contentType?: string | null;
  buttonType?: string;
  icon?: any;
  hideLink?: boolean;
  iconAlt?: string | null;
}

const CTAIconObjectHandler: React.FC<Props> = (props) => {
  const { icon: Icon } = props;
  const renderAsButton = props.buttonType !== 'text';
  if (renderAsButton) {
    const style =
      props.contentType === 'internalLink' ? 'pl-4' : 'pl-1 tablet:pl-2 laptop-standard:pl-4';
    return props.icon ? (
      <span className={style}>
        <Icon
          alt={props.iconAlt ?? ''}
          title={
            props.contentType === 'internalLink'
              ? 'Navigate to internal link'
              : 'Navigate to external link'
          }
        />
      </span>
    ) : (
      <></>
    );
  }
  if (!props.hideLink && props.icon) {
    return (
      <span className="svg">
        <Icon
          alt={props.iconAlt ?? ''}
          title={
            props.contentType === 'internalLink'
              ? 'Navigate to internal link'
              : 'Navigate to external link'
          }
        />
      </span>
    );
  }
  return <></>;
};

export default CTAIconObjectHandler;
