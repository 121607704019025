import React from 'react';

export interface OnClickContext {
  setOnClick: (newFunction: () => void) => void;
  onClick: () => void;
}
const SpecialClickContext = React.createContext<OnClickContext>({
  onClick: () => null,
  setOnClick: () => null,
});

export default SpecialClickContext;
