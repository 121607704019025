import React, { useState, useContext, useEffect } from 'react';
import ModalVideo from 'react-modal-video';
import MasterButton from './button/simpler-types/master';
import SimpleButton from './button/simpler-types/simple';
import SpecialClickContext from '../context/specialClickContext';
import Portal from './complements/portal';
import { Maybe } from '../../graphql-types';
import ctaStyleHandler from './button/complements/cta-style-handler';
import CTALinkTitleHandler from './button/complements/cta-link-title-handler';

interface Props {
  className?: string;
  styles?: string;
  textStyle?: string;
  buttonTitle?: Maybe<string>;
  buttonType?: string;
  description?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>>[];
  type?: string;
  linkTitle?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>>[];
  hideLink?: boolean;
  channel?: string;
  videoId?: Maybe<string>;
  videoURL?: Maybe<string>;
  iconAlt?: string;
  section?: string;
  iconLg?: any;
  iconMd?: any;
  iconSm?: any;
  icon?: any;
}

const VideoModal: React.FC<Props> = (props) => {
  const { icon: Icon } = props;
  const { setOnClick } = useContext(SpecialClickContext);
  const [isOpen, openModal] = useState(false);

  useEffect(() => {
    setOnClick(() => {
      openModal(!isOpen);
    });
  }, [setOnClick, openModal, isOpen]);

  if (props.buttonType !== 'text') {
    const buttonTitle = props.buttonTitle ?? '';
    const button =
      props.type === 'master' ? (
        <MasterButton
          iconLg={props.iconLg}
          iconMd={props.iconMd}
          iconSm={props.iconSm}
          title={buttonTitle}
          iconAlt={buttonTitle}
        />
      ) : (
        <SimpleButton title={buttonTitle} icon={props.icon} iconAlt={buttonTitle} />
      );

    const children =
      props.type === 'master' ? (
        <div className="container mb-32">
          <h1 className="text-center mb-6">{props.linkTitle}</h1>
          <div className="flex justify-center pt-4 tablet:pt-10 buttonWrapper">
            <button
              className={`iconLg bg-white ${props.styles} pl-1 pr-6 videoModalButton`}
              onClick={() => openModal(!isOpen)}
            >
              {button}
            </button>
          </div>
        </div>
      ) : (
        <>
          <button
            className={`iconSm cta-button-format-mobile px-2 text-orange-600 ${props.styles}`}
            onClick={() => openModal(!isOpen)}
          >
            {button}
          </button>
          <button
            className={`cta-button-format-desk iconLg px-2 text-orange-600 ${props.styles} h-12`}
            onClick={() => openModal(!isOpen)}
          >
            {button}
          </button>
        </>
      );

    return (
      <div>
        {(props.videoId || props.videoURL) && (
          <Portal>
            <ModalVideo
              channel={props.channel ?? 'vimeo'}
              isOpen={isOpen}
              videoId={props.videoId}
              url={props.videoURL}
              onClose={openModal}
            />
          </Portal>
        )}
        {children}
      </div>
    );
  }
  const title = CTALinkTitleHandler({
    section: props.section ?? '',
    linkTitle: props.linkTitle ?? '',
    buttonTitle: props.buttonTitle ?? '',
    hideLink: props.hideLink,
    textStyle: props.textStyle,
  });
  const style = ctaStyleHandler(props.section).concat(` ${props.className ?? ''}`);
  const icon =
    !props.hideLink && props.icon ? (
      <span className="svg">
        <Icon title={props.iconAlt ?? props.buttonTitle ?? ''} />
      </span>
    ) : (
      <></>
    );
  return (
    <div>
      {(props.videoId || props.videoURL) && (
        <Portal>
          <ModalVideo
            channel={props.videoURL ? 'custom' : 'vimeo'}
            isOpen={isOpen}
            videoId={props.videoId}
            url={props.videoURL}
            onClose={openModal}
          />
        </Portal>
      )}
      <div className={style}>
        {props.description &&
          (typeof props.description === 'string' ? <p>{props.description}</p> : props.description)}
        <p
          className={`${props.styles ?? 'cta-link-format'} ${
            (props.videoId || props.videoURL) && 'cursor-pointer'
          } inline-block`}
          onClick={() => openModal(!isOpen)}
        >
          {title}
          {icon}
        </p>
      </div>
    </div>
  );
};

export default VideoModal;
