import React from 'react';

interface SimpleButtonTypes {
  title: string;
  icon: any;
  iconAlt: string;
}

const SimpleButton: React.FC<SimpleButtonTypes> = (props) => {
  const { icon: Icon } = props;
  return (
    <div className="flex items-center">
      {props.title}
      <span className="pl-4">
        <Icon alt={props.iconAlt} />
      </span>
    </div>
  );
};

export default SimpleButton;
