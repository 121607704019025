import React from 'react';
import { ButtonProps } from '../buttonProps';

type ApplyButtonType = Pick<ButtonProps, 'styles' | 'buttonTitle'>;

const ApplyButton: React.FC<ApplyButtonType> = ({ styles = '', buttonTitle = '' }) => {
  return (
    <div className="flex">
      <a
        className={`iconSm laptop-standard:hidden 
        flex items-center bg-orange-700 text-white border-transparent px-8 py-6 
        ${styles} rounded-full`}
        href="#applyForm"
      >
        <p className="text-right">{buttonTitle}</p>
      </a>
      <a
        className={`iconLg hidden laptop-standard:flex
        flex items-center bg-orange-700 text-white border-transparent px-8 py-6 
        ${styles} rounded-full h-13`}
        href="#applyForm"
      >
        <p className="text-right">{buttonTitle}</p>
      </a>
    </div>
  );
};

export default ApplyButton;
