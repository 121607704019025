import React, { ReactElement } from 'react';
import ReactHtmlParser from 'react-html-parser';

interface Params {
  section: string;
  linkTitle: string | React.ReactElement<any, string | React.JSXElementConstructor<any>>[];
  buttonTitle: string;
  hideLink?: boolean;
  textStyle?: string;
}

const CTALinkTitleHandler = ({
  section = '',
  linkTitle = '',
  buttonTitle = '',
  hideLink = false,
  textStyle = '',
}: Params): ReactElement[] | string => {
  const isForCarousel = section === 'carousel';
  if (hideLink) {
    return ReactHtmlParser(`<span>${linkTitle}</span>`);
  }
  return isForCarousel
    ? ReactHtmlParser(`${linkTitle} <span class="${textStyle}">${buttonTitle}</span>`)
    : ReactHtmlParser(
        `<span class="title ${textStyle}">${linkTitle}</span> <span class="${textStyle}">${buttonTitle}</span>`
      );
};

export default CTALinkTitleHandler;
