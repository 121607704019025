import React from 'react';
import ctaStyleHandler from '../complements/cta-style-handler';
import CTAIconObjectHandler from '../complements/cta-icon-object-handler';
import CTALinkTitleHandler from '../complements/cta-link-title-handler';
import { ButtonProps } from '../buttonProps';

const ExternalLinkButton: React.FC<ButtonProps> = (props) => {
  const renderAsButton = props.buttonType !== 'text';
  const icon = CTAIconObjectHandler({
    contentType: props.contentType,
    buttonType: props.buttonType,
    icon: props.icon,
    hideLink: props.hideLink,
    iconAlt: props.buttonTitle,
  });
  let linkContent = <></>;

  if (renderAsButton) {
    const styleLinkMobile = `cta-button-format-mobile iconSm laptop-standard:hidden
    flex items-center bg-white rounded-full h-14 text-orange-600 ${props.styles}`;
    const styleLinkDesk = `cta-button-format-desk iconLg mobile:hidden laptop-standard:flex
    flex items-center bg-white rounded-full h-13 text-orange-600 ${props.styles}`;
    linkContent = (
      <>
        <p className="text-right">{props.buttonTitle}</p>
        {icon}
      </>
    );
    const linkContentButtonMobile = <div className={styleLinkMobile}>{linkContent}</div>;

    const linkContentButtonDesktop = <div className={styleLinkDesk}>{linkContent}</div>;

    return (
      <div className={`flex ${props.wrapperStyles ?? ''}`} onClick={props.onClick}>
        {props.externalLink ? (
          <>
            <a href={props.externalLink ?? ''} target="_blank" rel="noreferrer">
              {linkContentButtonMobile}
            </a>
            <a href={props.externalLink ?? ''} target="_blank" rel="noreferrer">
              {linkContentButtonDesktop}
            </a>
          </>
        ) : (
          <>
            {linkContentButtonMobile}
            {linkContentButtonDesktop}
          </>
        )}

        <div />
      </div>
    );
  }

  const title = CTALinkTitleHandler({
    section: props.section ?? '',
    linkTitle: props.linkTitle ?? '',
    buttonTitle: props.buttonTitle ?? '',
    hideLink: props.hideLink,
    textStyle: props.textStyle,
  });
  const style = ctaStyleHandler(props.section).concat(` ${props.className ?? ''}`);
  const styleLink = props.styles ?? 'cta-link-format';

  linkContent = (
    <div className={styleLink}>
      {title}
      {icon}
    </div>
  );

  return (
    <div className={style}>
      {props.externalLink ? (
        <a href={props.externalLink} target="_blank" rel="noreferrer">
          {linkContent}
        </a>
      ) : (
        linkContent
      )}
    </div>
  );
};

export default ExternalLinkButton;
