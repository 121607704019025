import React from 'react';
import { ButtonProps } from '../buttonProps';

type SubmitButtonType = Pick<
  ButtonProps,
  'buttonTitle' | 'internalLink' | 'textColor' | 'backgroundColor' | 'styles' | 'disabled'
>;

const SubmitButton: React.FC<SubmitButtonType> = (props) => {
  const backgroundColor = props.backgroundColor ?? 'bg-white';
  const textColor = props.textColor ?? 'text-black';
  const buttonLabel = props.disabled ? 'Submitting...' : props.buttonTitle;
  return (
    <a href={`${props.internalLink}`}>
      <div>
        <button
          type="submit"
          className={`iconSm laptop-standard:hidden 
        flex items-center rounded-full ${backgroundColor} ${textColor} ${props.styles}`}
          disabled={props.disabled}
        >
          {buttonLabel}
        </button>
        <button
          type="submit"
          className={`hidden laptop-standard:flex iconLg
        flex items-center rounded-full h-13 ${backgroundColor}
        ${textColor} ${props.styles}`}
          disabled={props.disabled}
        >
          {buttonLabel}
        </button>
        <div />
      </div>
    </a>
  );
};

export default SubmitButton;
