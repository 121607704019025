import React, { useContext } from 'react';
import { Link } from 'gatsby';
import ctaStyleHandler from '../complements/cta-style-handler';
import CTAIconObjectHandler from '../complements/cta-icon-object-handler';
import CTALinkTitleHandler from '../complements/cta-link-title-handler';
import { ButtonProps } from '../buttonProps';
import ActiveModalsContext from '../../../context/activeModalsContext';

const InternalButtonLink: React.FC<ButtonProps> = (props) => {
  const [activeModals, setActiveModals] = useContext(ActiveModalsContext);
  const renderAsButton = props.buttonType !== 'text';
  const icon = CTAIconObjectHandler({
    contentType: props.contentType ?? 'internalLink',
    buttonType: props.buttonType ?? '',
    icon: props.icon,
    hideLink: props.hideLink,
    iconAlt: '',
  });

  const isHashLink = props.internalLink?.includes('#');

  const specialNavigationForHash = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isHashLink) {
      event.preventDefault();
      setActiveModals({ ...activeModals, [props.internalLink?.replace('#', '') ?? '']: true });

      // should be done in a different file.
      if (props.internalLink === '#contact-us-form') {
        document
          .getElementById('contact-us-form')
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  if (renderAsButton) {
    const styleLinkMobile = `cta-button-format-mobile iconSm laptop-standard:hidden
    flex items-center bg-white rounded-full h-14 text-orange-600 ${props.styles}`;
    const styleLinkDesk = `cta-button-format-desk iconLg hidden laptop-standard:flex
    flex items-center bg-white rounded-full h-13 text-orange-600 ${props.styles}`;
    return (
      <div className={`flex ${props.wrapperStyles ?? ''}`}>
        <Link
          className={styleLinkMobile}
          to={isHashLink ? '#' : props.internalLink ?? ''}
          onClick={specialNavigationForHash}
        >
          {props.buttonTitle}
          {icon}
        </Link>
        <Link
          className={styleLinkDesk}
          to={isHashLink ? '#' : props.internalLink ?? ''}
          onClick={specialNavigationForHash}
        >
          {props.buttonTitle}
          {icon}
        </Link>
      </div>
    );
  }
  const title = CTALinkTitleHandler({
    section: props.section ?? '',
    linkTitle: props.linkTitle ?? '',
    buttonTitle: props.buttonTitle ?? '',
    hideLink: props.hideLink,
    textStyle: props.textStyle,
  });
  const style = ctaStyleHandler(props.section).concat(` ${props.className ?? ''}`);
  const styleLink = props.styles ?? 'cta-link-format';

  return (
    <div className={style}>
      <Link
        className={styleLink}
        to={isHashLink ? '#' : props.internalLink ?? ''}
        onClick={specialNavigationForHash}
      >
        {title}
        {icon}
      </Link>
    </div>
  );
};

export default InternalButtonLink;
